import TemplateCard from 'components/TemplateCard'
import { AudioPlayer, Image, FilePreview, Typography, VideoPlayer, Column } from 'design-system'
import { formatMessageText, hexToRGBA } from 'helpers'
import Caption from './Caption'
import { colors } from 'configs'

const categoryFiles = {
  audio: ({ file, caption }) => (
    <Column key={`${caption}_${file}`} minWidth='300px'>
      <AudioPlayer borderRadius='8px' src={file} />
      {caption && <Caption caption={caption} />}
    </Column>
  ),
  file: ({ file, caption, file_category, file_name, sender }) => (
    <Column key={file_name}>
      <Column
        p='10px'
        background={
          sender === 'contact'
            ? hexToRGBA(colors.grey[100], 0.2)
            : hexToRGBA(colors.violet[100], 0.7)
        }
        borderRadius='8px'
      >
        <FilePreview preview={file_category.value} fileName={file_name} downloadLink={file} />
      </Column>
      {caption && <Caption caption={caption} />}
    </Column>
  ),
  video: ({ file, caption }) => (
    <>
      <VideoPlayer borderRadius='8px' height='auto' src={file} />
      {caption && <Caption caption={caption} />}
    </>
  ),
  sticker: ({ file, caption }) => (
    <>
      <Image width='125px' src={file} alt={caption || 'image'} borderRadius='8px' />
      {caption && <Caption caption={caption} />}
    </>
  ),
  image: ({ file, caption, setExpand }) => (
    <>
      <Image
        width='166px'
        objectFit='cover'
        height='166px'
        src={file}
        alt={caption || 'image'}
        onClick={() => setExpand({ file, caption })}
        borderRadius='8px'
        cursor='pointer'
      />
      {caption && <Caption caption={caption} />}
    </>
  ),
}

const messagesTypeDict = {
  text: ({ message }) => (
    <Typography color='grey.500' fontSize='14px'>
      {formatMessageText(message?.body?.text)}
    </Typography>
  ),
  list: ({ message }) => (
    <Typography color='grey.500' fontSize='14px' {...message?.body_styles}>
      {message?.body?.text}
    </Typography>
  ),
  file: ({ message, setExpand }) =>
    message.files.map((file) =>
      categoryFiles[file.file_type.value]({ ...file, setExpand, sender: message.sender_type }),
    ),
  template: ({ message: { header, body, buttons, footer } }) => (
    <TemplateCard
      message
      data={{ content: body?.text, header: header?.text, buttons, footer: footer?.text }}
    />
  ),
  location: () => <div>localização</div>,
  contact: () => <div>contato</div>,
}

export default messagesTypeDict
