import { createContext, useCallback, useEffect, useMemo, useRef } from 'react'
import {
  useTicketReducer,
  actionTicketTypes,
  actionMessagesTypes,
  actionSelectedTicketTypes,
  actionCurrentStatusTypes,
} from './store'
import md5 from 'md5'
import { messageStatusDict, system } from 'configs'
import { socket } from 'providers/socket'
import { useAuth } from 'hooks'
import { format } from 'date-fns'

export const TicketContext = createContext({})

export const TicketProvider = ({ children }) => {
  const [state, dispatch] = useTicketReducer()
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const {
    userData: { company_user },
  } = useAuth()

  const { SOCKET_ENVIRONMENT } = system

  const connectedRoomsRef = useRef([])

  const { selected_ticket, current_status, tickets_list, tickets_list_filters } = state

  const isPendingOrAttendingTab = ['pending', 'attending'].includes(current_status)
  const isClosedTab = current_status === 'closed'

  const roomcode = useMemo(
    () => `${SOCKET_ENVIRONMENT}_chatgds_ticket_${md5(`${selected_ticket?.id}`)}`,
    [selected_ticket, SOCKET_ENVIRONMENT],
  )

  const handlerEventMessage = useCallback(
    ({ data, event }) => {
      const activeTicket = Number(selected_ticket?.id) === Number(data?.ticket?.id)

      if (!activeTicket) return

      if (event === 'created') {
        dispatch({
          type: actionMessagesTypes.ADD_MESSAGE,
          payload: { ...data, key: format(data.created_at, 'dd/MM/yyyy') },
        })
        if (data?.ticket?.id) {
          dispatch({
            type: actionSelectedTicketTypes.UPDATE_SELECTED_TICKET,
            payload: { ...data.ticket },
          })
        }
      }

      if (event === 'status-update') {
        if (data?.ticket?.id && data?.ticket?.status === current_status) {
          dispatch({
            type: actionTicketTypes.UPDATE_TICKET,
            payload: {
              ...selected_ticket,
              last_message: {
                ...selected_ticket?.last_message,
                status: messageStatusDict[data?.current_status],
              },
            },
          })
        }

        if (data?.id) {
          dispatch({
            type: actionMessagesTypes.UPDATE_MESSAGES,
            payload: {
              id: data.id,
              status: messageStatusDict[data?.current_status],
              key: format(data?.created_at, 'dd/MM/yyyy'),
            },
          })
        }
      }
    },
    [dispatch, selected_ticket, current_status],
  )

  const handlerEventTicket = useCallback(
    ({ data, event }) => {
      const activeTicket = Number(selected_ticket?.id) === Number(data?.id)
      const findTicket = tickets_list?.find((t) => t.id === data.id)
      const hasActiveUserFilter = tickets_list_filters?.attendant === 'attendant'

      const isPendingOrAttending = ['pending', 'attending'].includes(data.status)
      const isClosed = data.status === 'closed'

      if (hasActiveUserFilter && data?.attendant?.id !== company_user?.id) return

      if (event === 'update') {
        if (activeTicket) {
          dispatch({ type: actionSelectedTicketTypes.UPDATE_SELECTED_TICKET, payload: data })
        }

        if (findTicket) {
          if ((isPendingOrAttendingTab && isPendingOrAttending) || (isClosedTab && isClosed)) {
            dispatch({ type: actionTicketTypes.UPDATE_TICKET, payload: data })
          } else if (
            (isPendingOrAttendingTab && isClosed) ||
            (isClosedTab && isPendingOrAttending)
          ) {
            dispatch({ type: actionTicketTypes.REMOVE_TICKET, payload: data.id })
          }
        } else if ((isPendingOrAttendingTab && isPendingOrAttending) || (isClosedTab && isClosed)) {
          dispatch({ type: actionTicketTypes.ADD_TICKET, payload: data })
        }
      } else if (event === 'created' && !findTicket) {
        if ((isPendingOrAttendingTab && isPendingOrAttending) || (isClosedTab && isClosed)) {
          dispatch({ type: actionTicketTypes.ADD_TICKET, payload: data })
        }
      }
    },
    [
      dispatch,
      selected_ticket?.id,
      tickets_list,
      isPendingOrAttendingTab,
      isClosedTab,
      tickets_list_filters,
      company_user,
    ],
  )

  useEffect(() => {
    if (!connectedRoomsRef.current.includes(roomcode)) {
      socket.emit('join-room', roomcode)
      connectedRoomsRef.current.push(roomcode)
    }

    socket.on('ticket-message', handlerEventMessage)
    socket.on('ticket', handlerEventTicket)

    return () => {
      socket.off('ticket-message', handlerEventMessage)
      socket.off('ticket', handlerEventTicket)
    }
  }, [roomcode, handlerEventMessage, handlerEventTicket, company_user])

  return (
    <TicketContext.Provider
      value={{
        state,
        dispatch,
        actionTicketTypes,
        actionMessagesTypes,
        actionSelectedTicketTypes,
        actionCurrentStatusTypes,
      }}
    >
      {children}
    </TicketContext.Provider>
  )
}

export default TicketProvider
