import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { addDays, format } from 'date-fns'

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
  Table,
  Icon,
  Row,
  Input,
  Form,
  Datepicker,
} from 'design-system'
import { ProtectedComponent } from 'components'

import { notify, hexToRGBA } from 'helpers'
import { inviteStatusDict } from 'configs'

import { createCompanyInvite, showCompanyInviteList, updateCompanyInvite } from 'services'

const ModalInvite = ({ data, onClose, setModalConfirmation, dispatch, actionTypes, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previus: null,
    page_size: 50,
    total_pages: 1,
  })

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    props.open && getInfo()

    return () => reset({ email: '', expiration_date: addDays(new Date(), 14) })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  const getInfo = async () => {
    try {
      setLoading(true)
      const { data } = await showCompanyInviteList()

      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data?.results,
        tab: 'invites',
        filter: 'email',
      })

      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar listagem de convites.')
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateInviteStatus = async (values, status) => {
    try {
      setLoading(true)
      const { data } = await updateCompanyInvite(values.id, status)

      dispatch({
        type: actionTypes.UPDATE_ITEM,
        payload: data,
        tab: 'invites',
        filter: 'email',
      })
      notify.success('Convite atualizado com sucesso.')
    } catch {
      notify.error('Não foi possível atualizar o convite no momento.')
    } finally {
      setTimeout(() => setLoading(false), 3000)
    }
  }

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      values.expiration_date = format(values.expiration_date, 'dd/MM/yyyy 23:59')

      const { data } = await createCompanyInvite(values)

      dispatch({
        type: actionTypes.ADD_ITEM,
        payload: data,
        tab: 'invites',
        filter: 'email',
      })
      reset({ email: '', expiration_date: new Date() })
      notify.success('Convite enviado com sucesso.')
    } catch {
      notify.error('Não foi possível enviar o convite no momento.')
    } finally {
      setLoading(false)
    }
  }

  const clearOnClose = () => {
    reset({ email: '', expiration_date: new Date() })
    onClose()
  }

  const columns = [
    {
      header: 'E-mail',
      field: 'email',
    },
    {
      header: 'Status',
      render: ({ status }) => (
        <Row gap='8px'>
          <Typography
            width='90px'
            backgroundColor={hexToRGBA(inviteStatusDict[status].color, 0.2)}
            color={inviteStatusDict[status].color}
          >
            {inviteStatusDict[status].label}
          </Typography>
        </Row>
      ),
    },
    {
      header: 'Data de expiração',
      render: ({ expiration_date }) => (
        <Typography>{format(expiration_date, 'dd/MM/yyyy')}</Typography>
      ),
    },
    {
      header: 'Ações',
      render: (row) => (
        <ProtectedComponent
          customProtected={row.status !== 'canceled' && row.status !== 'pending'}
          allowedRoles={['manage_users']}
          unauthorizedComponent={false}
        >
          <Row gap='20px' width='100%'>
            <Icon
              cursor='pointer'
              icon={row.status === 'canceled' ? 'Refresh' : 'Close'}
              color={row.status === 'canceled' || row.status === 'pending' ? 'primary' : 'grey.200'}
              onClick={() =>
                (row.status === 'canceled' || row.status === 'pending') &&
                !loading &&
                handleUpdateInviteStatus(row, {
                  status: row.status === 'canceled' ? 'pending' : 'canceled',
                })
              }
            />
          </Row>
        </ProtectedComponent>
      ),
    },
  ]

  return (
    <Modal size='md' onClose={clearOnClose} {...props}>
      <ModalHeader onClose={clearOnClose}>
        <Typography variant='title' color='primary'>
          Convidar usuário
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row gap='8px'>
            <Row>
              <Input label='E-mail' {...register('email', { required: true })} />
            </Row>
            <Row>
              <Datepicker
                maxWidth='130px'
                control={control}
                label='Data de expiração'
                required
                {...register('expiration_date')}
              />
            </Row>
            <Row alignItems='flex-end'>
              <Button type='submit' disabled={loading}>
                Enviar convite
              </Button>
            </Row>
          </Row>
          {errors?.email && (
            <Typography ml='5px' variant='helper' color='danger'>
              Campo obrigatório
            </Typography>
          )}
        </Form>
        <Row width='100%' mt='16px'>
          <Table
            width='100%'
            mt='24px'
            title='invites'
            columns={columns}
            data={data}
            pagination={pagination}
            setPagination={setPagination}
            emptyMessage='Parece que você ainda não criou um convite. Crie convites para adicionar usuários à sua empresa.'
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button maxWidth='100px' variant='text' color='grey.500' onClick={clearOnClose}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalInvite
