import { useState, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { space, layout, color, compose, typography } from 'styled-system'

const styles = compose(layout, space, color, typography)

const TooltipContainer = styled.div`
  display: flex;
  position: relative;
  ${styles};
`

const TooltipText = styled.div`
  position: fixed;
  width: max-content;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  white-space: ${({ whiteSpace }) => whiteSpace || 'initial'};
  background-color: ${({ theme }) => theme.colors.black[200]};
  top: ${({ tooltipPosition }) => `${tooltipPosition.top}px`};
  left: ${({ tooltipPosition }) => `${tooltipPosition.left}px`};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: 1050;
  ${styles};
`

const calculatePosition = (position, top, left, width, height, offsetWidth, offsetHeight) => {
  const positions = {
    top: { top: top - offsetHeight - 10, left: left + width / 2 - offsetWidth / 2 },
    bottom: { top: top + height + 10, left: left + width / 2 - offsetWidth / 2 },
    left: { top: top + height / 2 - offsetHeight / 2, left: left - offsetWidth - 10 },
    right: { top: top + height / 2 - offsetHeight / 2, left: left + width + 10 },
  }

  let { top: newTop, left: newLeft } = positions[position]

  if (newTop < 0) newTop = top + height + 10
  if (newTop + offsetHeight > window.innerHeight) newTop = top - offsetHeight - 10
  if (newLeft < 0) newLeft = 10
  if (newLeft + offsetWidth > window.innerWidth) newLeft = window.innerWidth - offsetWidth - 10

  return { top: newTop, left: newLeft }
}

const Tooltip = ({ title, children, color, position = 'top', containerProps, ...rest }) => {
  const [visible, setVisible] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const tooltipRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    if (visible) {
      const { top, left, width, height } = containerRef.current.getBoundingClientRect()
      const { offsetWidth, offsetHeight } = tooltipRef.current
      const newPosition = calculatePosition(
        position,
        top,
        left,
        width,
        height,
        offsetWidth,
        offsetHeight,
      )
      setTooltipPosition(newPosition)
    }
  }, [visible, position])

  const portalContainer = document.getElementById('portal-container')

  return (
    <TooltipContainer
      ref={containerRef}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      {...containerProps}
    >
      {children}
      {portalContainer &&
        createPortal(
          <TooltipText
            ref={tooltipRef}
            visible={title && visible}
            className={position}
            tooltipPosition={tooltipPosition}
            bgcolor={color}
            {...rest}
          >
            {title}
          </TooltipText>,
          portalContainer,
        )}
    </TooltipContainer>
  )
}

Tooltip.defaultProps = {
  color: 'primary',
  position: 'top',
}

export default Tooltip
