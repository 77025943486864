import { messageStatusDict } from 'configs'
import { format } from 'date-fns'
import { Icon, Row, Typography } from 'design-system'

const MessageFooter = ({ createdAt, status, senderType, userSender }) => {
  const ignoreStatusSender = ['system', 'contact', 'internal']
  const ignoreUserSender = ['contact', 'internal']

  return (
    <Row
      gap='4px'
      mt='8px'
      width='100%'
      justifyContent='space-between'
      alignItems='center'
      className='cursor-pointer'
    >
      {!ignoreUserSender.includes(senderType) ? (
        <Typography
          width='80px'
          ellipsis
          className='conversation__user_sender'
          fontSize='12px'
          color='grey.200'
        >
          {userSender?.name || 'Sistema'}
        </Typography>
      ) : (
        <Typography></Typography>
      )}
      <Row gap='4px'>
        {createdAt && (
          <Typography fontSize='12px' textAlign='right'>
            {format(createdAt, 'HH:mm')}
          </Typography>
        )}
        {status && !ignoreStatusSender.includes(senderType) && (
          <Icon
            size='14'
            icon={messageStatusDict[status?.value]?.icon}
            color={messageStatusDict[status?.value]?.color || '#7F7F7F'}
          />
        )}
      </Row>
    </Row>
  )
}

export default MessageFooter
